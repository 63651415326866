import { IFlex } from "@types";
import { createDataAttr } from "@utils";
import { render } from "flex-render";

type FlexMessageProps = {
  message: IFlex;
};

export const FlexMessage = ({ message }: FlexMessageProps) => {
  const result = render(message.contents) ?? `<div>Empty Flex Message</div>`;
  return (
    <div
      {...createDataAttr("flex-message")}
      dangerouslySetInnerHTML={{ __html: result }}
    />
  );
};
