import React, { RefObject, useEffect, useRef } from "react";
import { useChatContext } from "@containers/chat";
import { useActor } from "@xstate/react";
import { BOTTOM_SCROLL_THRESHOLD } from "@constants";
import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/pro-solid-svg-icons";

type IMessageBoxInnerProps = {
  wrapRef: RefObject<HTMLDivElement>;
  children: React.ReactNode;
};

export const MessageBoxInner = React.memo(
  ({ wrapRef, children }: IMessageBoxInnerProps) => {
    const machineService = useChatContext();
    const [state] = useActor(machineService.chatService);
    const innerRef = useRef(null);
    const isUserScrolling = useRef(false);
    const scrollTimeout = useRef<NodeJS.Timeout | null>(null);
    const [showGoToBottom, setShowGoToBottom] = React.useState(false);

    useEffect(() => {
      const observer = new ResizeObserver((entries) => {
        if (!wrapRef.current) return;
        if (isUserScrolling.current) return;
        const isFirstFetch = state.matches("firstFetchMessageSuccess");
        const isSendingMessage = state.matches("sendingMessage");
        const isUploadingMessage = state.matches("uploadingMessage");
        const isNewIncomingMessage = state.matches("newIncomingMessage");
        const { scrollTop, scrollHeight } = wrapRef.current;

        for (let entry of entries) {
          if (isFirstFetch || isSendingMessage || isUploadingMessage) {
            // Use requestAnimationFrame to wait for the next animation frame
            requestAnimationFrame(() => {
              if (!wrapRef.current) return;
              // Scroll to the bottom
              wrapRef.current.scrollTop = entry.contentRect.height;
            });
          }
          // Calculate the difference between total scroll height and current scroll position
          const scrollDifference = Math.abs(scrollHeight - scrollTop);
          // new incoming message and scroll around bottom
          if (
            isNewIncomingMessage &&
            scrollDifference <= BOTTOM_SCROLL_THRESHOLD
          ) {
            requestAnimationFrame(() => {
              if (!wrapRef.current) return;
              // Scroll to the bottom
              wrapRef.current.scrollTop = scrollHeight;
            });
          }
        }
      });

      if (innerRef.current) {
        observer.observe(innerRef.current);
      }

      return () => {
        observer.disconnect();
      };
    }, [state]);

    useEffect(() => {
      const handleScroll = () => {
        if (wrapRef.current) {
          const { scrollTop, scrollHeight, clientHeight } = wrapRef.current;
          console.log(
            scrollHeight - scrollTop,
            BOTTOM_SCROLL_THRESHOLD,
            showGoToBottom
          );
          if (
            !showGoToBottom &&
            scrollHeight - scrollTop > BOTTOM_SCROLL_THRESHOLD
          ) {
            setShowGoToBottom(true);
          } else if (
            showGoToBottom &&
            scrollHeight - scrollTop <= BOTTOM_SCROLL_THRESHOLD
          ) {
            setShowGoToBottom(false);
          }

          // Check if user is near the bottom (you can adjust the threshold)
          if (scrollHeight - scrollTop !== clientHeight) {
            isUserScrolling.current = true;

            if (scrollTimeout.current) clearTimeout(scrollTimeout.current);

            // Reset the flag after user stops scrolling for 200ms
            scrollTimeout.current = setTimeout(() => {
              isUserScrolling.current = false;
            }, 200);
          } else {
            isUserScrolling.current = false;
          }
        }
      };

      const currentScrollRef = wrapRef.current;
      if (currentScrollRef) {
        currentScrollRef.addEventListener("scroll", handleScroll);
      }

      return () => {
        if (currentScrollRef) {
          currentScrollRef.removeEventListener("scroll", handleScroll);
        }
      };
    }, [showGoToBottom]);
    return (
      <div ref={innerRef} className="flex-1">
        {children}
        <AnimatePresence>
          {showGoToBottom && (
            <motion.button
              onClick={() => {
                if (wrapRef.current) {
                  wrapRef.current.scrollTop = wrapRef.current.scrollHeight;
                }
              }}
              className="fixed flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[white] shadow-md"
              style={{
                left: "50%",
                transform: "translateX(-50%)",
              }}
              initial={{ opacity: 0, bottom: -80, scale: 0.5 }}
              animate={{ opacity: 1, bottom: 80, scale: 1 }}
              exit={{ opacity: 0, bottom: -80, scale: 1 }}
              transition={{
                type: "spring",
                bounce: 0.35,
                duration: 1,
              }}
            >
              <FontAwesomeIcon className="text-[black]" icon={faArrowDown} />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    );
  }
);
