import { BASE_URL } from "@configs/api";
import { POSTBACK_PREFIX } from "@constants";
import {
  EMessageStatus,
  EMessageType,
  ESender,
  IAttachment,
  IButton,
  ICarouselList,
  ICombinedMessage,
  IFlex,
  IImageCarousel,
  IImageMap,
  IImageMessage,
  IInCommingMessage,
  IInternalAttachment,
  IInternalGenMessage,
  ITextMessage,
  IVideo,
} from "@types";
import { getFileExtensionFromString } from "@utils";
import { compact } from "lodash";
import qs from "qs";

export const messageTransformer = (
  uid: string,
  rawMessage: IInCommingMessage[]
): (ICombinedMessage | ICombinedMessage[])[] => {
  const messages: (ICombinedMessage | ICombinedMessage[] | null)[] =
    rawMessage.map((message) => {
      if (message.t === "command") {
        return null;
      }
      if (message.t === "livechat-started") {
        return null;
      }

      const messageResult = transformMessage(uid, message);
      return messageResult;
    });

  const result = compact(messages);
  return result;
};

const getMessageByType = (
  message: ICombinedMessage,
  sender: ESender,
  createdAt: string
) => {
  switch (message.type) {
    case EMessageType.IMAGE:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.IMAGE,
        originalContentUrl: message.originalContentUrl,
        previewImageUrl: message.originalContentUrl,
        quickReply: message.quickReply,
      } as IImageMessage;
    case EMessageType.CAROUSEL:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.CAROUSEL,
        altText: message.altText,
        columns: message.columns,
        quickReply: message.quickReply,
        imageAspectRatio: message.imageAspectRatio,
        imageSize: message.imageSize,
      } as ICarouselList;
    case EMessageType.IMAGE_CAROUSEL:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.IMAGE_CAROUSEL,
        altText: message.altText,
        columns: message.columns,
        quickReply: message.quickReply,
      } as IImageCarousel;
    case EMessageType.BUTTONS:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.BUTTONS,
        altText: message.altText,
        text: message.text,
        actions: message.actions,
        quickReply: message.quickReply,
      } as IButton;
    case EMessageType.VIDEO:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.VIDEO,
        quickReply: message.quickReply,
        originalContentUrl: message.originalContentUrl,
        previewImageUrl: message.previewImageUrl,
      } as IVideo;
    case EMessageType.FLEX:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.FLEX,
        quickReply: message.quickReply,
        altText: message.altText,
        contents: message.contents,
      } as IFlex;
    case EMessageType.IMAGEMAP:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.IMAGEMAP,
        quickReply: message.quickReply,
        altText: message.altText,
        actions: message.actions,
        baseSize: message.baseSize,
        baseUrl: message.baseUrl,
        imgHeight: message.imgHeight,
        imgUrl: message.imgUrl,
        imgWidth: message.imgWidth,
      } as IImageMap;
    case EMessageType.ATTACHMENT:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.ATTACHMENT,
        url: message.url,
      } as IAttachment;
    default:
      return {
        id: message.id,
        sender: sender,
        status: EMessageStatus.SENT,
        createdAt: createdAt,
        type: EMessageType.TEXT,
        text: replacePostback(message.text),
        quickReply: message.quickReply,
      } as ITextMessage;
  }
};

export const replacePostback = (str: string) => {
  try {
    const assignTicketPrefix = `${POSTBACK_PREFIX}action=#t/`;
    if (str.includes(assignTicketPrefix)) {
      const displayMsgValue = str.split("displayMsg=")[1];
      return displayMsgValue;
    }
    const postbackText = `${POSTBACK_PREFIX}action=`;
    if (!str.includes(`${postbackText}`)) return str;
    const replacePostback = str.replace(`${POSTBACK_PREFIX}action=`, "");
    const { displayMsg } = qs.parse(replacePostback);
    if (!displayMsg) {
      return "";
    }
    if (displayMsg) {
      return displayMsg.toString();
    }
    return replacePostback;
  } catch (error) {
    return str;
  }
};

const genMessageFromAttachment = (
  message: IInCommingMessage,
  sender: ESender,
  createdAt: string
) => {
  const attachments = message.attachments;
  if (attachments && attachments.length > 0) {
    const defaultProp = {
      id: message._id,
      sender,
      createdAt,
      status: EMessageStatus.SENT,
    };
    // image message
    if (attachments[0].image_url) {
      const url =
        message.fileUpload?.publicFilePath ||
        `${BASE_URL}${attachments[0].image_url}`;
      return {
        ...defaultProp,
        type: EMessageType.IMAGE,
        originalContentUrl: url,
        previewImageUrl: url,
      } as IImageMessage;
    }

    // video message
    if (attachments[0].video_type) {
      const url =
        message.fileUpload?.publicFilePath ||
        `${BASE_URL}${attachments[0].video_url}`;
      return {
        ...defaultProp,
        type: EMessageType.VIDEO,
        originalContentUrl: url,
        previewImageUrl: url,
      } as IVideo;
    }

    // attachment message
    if (attachments[0].title_link) {
      const url =
        message.fileUpload?.publicFilePath ||
        `${BASE_URL}${attachments[0].title_link}`;
      return {
        ...defaultProp,
        type: EMessageType.ATTACHMENT,
        name: attachments[0].title,
        url: url,
        ext: getFileExtensionFromString(attachments[0].title),
      } as IAttachment;
    }

    return null;
  }
  return null;
};

export const transformMessage = (
  uid: string,
  message: IInCommingMessage
): ICombinedMessage | ICombinedMessage[] | null => {
  try {
    // check for attachments
    const sender = uid === message.u._id ? ESender.USER : ESender.BOT;
    if (message.attachments && message.attachments.length > 0) {
      const messageResult = genMessageFromAttachment(
        message,
        sender,
        message.ts
      );
      return messageResult;
    }
    const msg: ICombinedMessage[] = JSON.parse(message.msg);
    // multiple message in one object
    if (msg.length > 1) {
      let messageArray: ICombinedMessage[] = [];
      msg.forEach((item: ICombinedMessage) => {
        const messageResult = getMessageByType(item, sender, message.ts);
        messageArray.push(messageResult);
      });
      return messageArray;
    }
    const messageResult: ICombinedMessage = getMessageByType(
      msg[0],
      sender,
      message.ts
    );

    return messageResult;
    // }
  } catch (error) {
    console.warn("error while transform message", error);
    return {
      id: message._id,
      sender: uid === message.u._id ? ESender.USER : ESender.BOT,
      status: EMessageStatus.SENT,
      createdAt: message.ts,
      type: EMessageType.TEXT,
      text: replacePostback(message.msg),
    };
  }
};

export const genInternalMessage = (
  msgType: EMessageType,
  status: EMessageStatus,
  payload: IInternalGenMessage,
  ts?: string
) => {
  const defaultPayload = {
    id: payload._id,
    sender: ESender.USER,
    status: status,
    createdAt: ts,
  };
  let message: ICombinedMessage | null = null;

  if (msgType === EMessageType.TEXT) {
    message = {
      ...defaultPayload,
      type: EMessageType.TEXT,
      text: payload.msg as string,
    };
  }
  if (msgType === EMessageType.IMAGE) {
    message = {
      ...defaultPayload,
      type: EMessageType.IMAGE,
      previewImageUrl: payload.msg as string,
      originalContentUrl: payload.msg as string,
    };
  }
  if (msgType === EMessageType.VIDEO) {
    message = {
      ...defaultPayload,
      type: EMessageType.VIDEO,
      previewImageUrl: payload.msg as string,
      originalContentUrl: payload.msg as string,
    };
  }
  if (msgType === EMessageType.ATTACHMENT) {
    const msg = payload.msg as IInternalAttachment;
    message = {
      ...defaultPayload,
      type: EMessageType.ATTACHMENT,
      name: msg.name,
      ext: msg.ext,
      url: msg.url,
    };
  }
  return message;
};
