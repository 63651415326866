import {
  EFileReaderError,
  EMessageType,
  ICombineFileReaderOutput,
  ICombineOtgFnOutput,
  ICombineOtgParams,
  IOtgMsgFnAttachmentOutput,
  IOtgMsgFnPostbackOutput,
  IOtgMsgFnTextOutput,
} from "@types";
import { getFileExtensionFromString } from "@utils";
import uniqid from "uniqid";
import { checkFileSize } from "./checkFileSize";
import { POSTBACK_PREFIX } from "@constants";

export const otgMsgGenerator = async (
  params: ICombineOtgParams
): Promise<ICombineOtgFnOutput | null> => {
  if (params.type === EMessageType.TEXT) {
    const newMsg: IOtgMsgFnTextOutput = {
      msgType: EMessageType.TEXT,
      payload: {
        _id: uniqid(),
        msg: params.msg,
        rid: params.rid,
        token: params.token,
      },
    };
    return newMsg;
  }

  if (params.type === EMessageType.FLOW_POSTBACK) {
    const newMsg: IOtgMsgFnPostbackOutput = {
      msgType: EMessageType.POSTBACK,
      payload: {
        _id: uniqid(),
        msg: `${POSTBACK_PREFIX}action=${params.msg}&displayMsg=${params.displayMsg}`,
        rid: params.rid,
        token: params.token,
        displayMsg: params.displayMsg,
      },
    };
    return newMsg;
  }

  if (params.type === EMessageType.POSTBACK) {
    const newMsg: IOtgMsgFnPostbackOutput = {
      msgType: EMessageType.POSTBACK,
      payload: {
        _id: uniqid(),
        msg: `${POSTBACK_PREFIX}action=${params.msg}&displayMsg=${params.displayMsg}`,
        rid: params.rid,
        token: params.token,
        displayMsg: params.displayMsg,
      },
    };
    return newMsg;
  }

  if (params.type === EMessageType.ASSIGN_TICKET_POSTBACK) {
    const newMsg: IOtgMsgFnPostbackOutput = {
      msgType: EMessageType.POSTBACK,
      payload: {
        _id: uniqid(),
        msg: `${POSTBACK_PREFIX}action=#t/${params.templateId}/${params.actionId}&displayMsg=${params.displayMsg}`,
        rid: params.rid,
        token: params.token,
        displayMsg: params.displayMsg,
      },
    };
    return newMsg;
  }

  if (params.type === EMessageType.ATTACHMENT) {
    const checkFileSizeResult = checkFileSize(params.file);
    if (!checkFileSizeResult.status) {
      alert(checkFileSizeResult.message);
      return null;
    }
    const data = await fileReaderAsync(params.file);
    // if there is error when read file
    if (data.type === EFileReaderError.READ_FILE_ERROR) {
      return null;
    }

    const newMsg: IOtgMsgFnAttachmentOutput = {
      file: params.file,
      msgType: data.type,
      payload: {
        _id: uniqid(),
        msg: data.payload as string,
        rid: params.rid,
        token: params.token,
      },
    };

    return newMsg;
  }

  return null;
};

export const fileReaderAsync = async (file: File) => {
  const reader = new FileReader();
  const promise = new Promise<ICombineFileReaderOutput>((resolve, reject) => {
    reader.onload = function (e) {
      try {
        const { target } = e;

        // reject when no target
        if (!target) {
          reject({
            type: EFileReaderError.READ_FILE_ERROR,
          });
          return;
        }
        // image
        if (file.type.includes("image")) {
          resolve({
            type: EMessageType.IMAGE,
            payload: target.result,
          });
        }
        // video
        if (file.type.includes("video")) {
          resolve({
            type: EMessageType.VIDEO,
            payload: target.result,
          });
        }
        // other file type
        const nameAndExt = getFileExtensionFromString(file.name);
        resolve({
          type: EMessageType.ATTACHMENT,
          payload: {
            name: file.name,
            ext: nameAndExt,
            // TODO: change this
            url: target.result as string,
          },
        });
      } catch (error) {
        console.error("error read file", error);
        // reject when code above error
        reject({
          type: EFileReaderError.READ_FILE_ERROR,
        });
      }
    };
    reader.onerror = (error: ProgressEvent<FileReader>) => {
      console.error("error read file", error);
      // reject when read file failed
      reject({
        type: EFileReaderError.READ_FILE_ERROR,
      });
    };

    reader.readAsDataURL(file);
  });

  return await promise;
};
