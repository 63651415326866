import { EMessageActionType, IButton, IMessageAction } from "@types";
import { createDataAttr } from "@utils";
interface IOptionMessageProps {
  message: IButton;
  onSendAction: (action: IMessageAction) => void;
}

export const OptionsMessage = ({
  message,
  onSendAction,
}: IOptionMessageProps) => {
  const _renderButton = (button: IMessageAction) => {
    if (
      button.type === EMessageActionType.DATE_TIME_PICKER ||
      button.type === EMessageActionType.CAMERA_ACTION
    ) {
      <button className="cursor-pointer py-4 text-blue-700">
        {button.label}
      </button>;
    }
    // link action
    if (button.type === EMessageActionType.URI_ACTION) {
      return (
        <a
          className="cursor-pointer py-4 text-center text-blue-700"
          href={button.uri}
          target="_blank"
        >
          {button.label}
        </a>
      );
    }
    // call action
    if (button.type === EMessageActionType.CALL_ACTION) {
      return (
        <a
          className="cursor-pointer py-4 text-center text-blue-700"
          href={`tel:${button.phoneNo}`}
        >
          {button.label}
        </a>
      );
    }

    return (
      <button
        className="cursor-pointer overflow-hidden text-ellipsis px-3 py-4 text-blue-700"
        onClick={() => onSendAction(button)}
      >
        {button.label}
      </button>
    );
  };

  return (
    <div className="flex items-center" {...createDataAttr("option-message")}>
      <div className="w-full min-w-[240px] max-w-md rounded-lg bg-white shadow-lg">
        <p className="mb-4 break-words p-6 text-gray-600">{message.text}</p>
        <hr className="my-2 border-t" />
        <div>
          {message.actions &&
            message.actions.map((button: IMessageAction, index: number) => (
              <div
                title={button.label}
                className="mx-auto flex flex-col"
                key={button.id}
                {...createDataAttr("option-button")}
              >
                {_renderButton(button)}

                {index !== message.actions.length - 1 && (
                  <hr className="my-2 border-t" />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
