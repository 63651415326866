import { DatetimePickerAction, MessageAction, URIAction } from "@line/bot-sdk";

export const enum EMessageActionType {
  URI_ACTION = "uri",
  CALL_ACTION = "call_action",
  TEMPLATE_ACTION = "template_action",
  CAMERA_ACTION = "camera",
  MESSAGE_ACTION = "message",
  FLOW_ACTION = "flow_action",
  DATE_TIME_PICKER = "datetimepicker",
  ASSIGN_TICKET = "assign_ticket",
}

export type IUriAction = URIAction;

export type ICallAction = {
  type: "call_action";
  id: string;
  label?: string;
  phoneNo: string;
};

export type ITemplateAction = {
  type: "template_action";
  label?: string;
  templateName: string;
};

export type IFlowAction = {
  type: "flow_action";
  label?: string;
  action: {
    id: string;
    name: string;
  };
  flowEngine: {
    flowEngineId: string;
    flowEngineEndpoint: string;
  };
};

export type IAssignTicketActionType = "flow_action" | "template_action";
export type IDropdownOption = {
  id: string;
  name: string;
  label: string;
  value: string;
};

export type IAssignTicketAction = {
  id: string;
  templateId: string;
  type: "assign_ticket";
  label: string;
  team: string;
  tags: string[];
  stopBotMode: boolean;
  secondaryAction: SecondaryAction;
};

export type SecondaryAction = {
  id?: string;
  type: IAssignTicketActionType;
  templateName?: string;
  flowEngine?: IDropdownOption;
  action?: IDropdownOption;
};

export type IMessageAction = {
  id: string;
  label: string;
} & (
  | MessageAction
  | DatetimePickerAction
  | IUriAction
  | ICallAction
  | ITemplateAction
  | IFlowAction
  | IAssignTicketAction
  | {
      type: "camera";
    }
);
